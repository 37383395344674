<template>
  <navbar />

  <div class="my-4 mx-xl-4 mx-sm-3 mx-2">
    <div class="d-lg-flex c-row">
      <div class="leftbar">
        <scene-tank-comp :path="modelInfo.path" />
        <h5 class="mt-md-3 mt-2">{{ modelInfo.title }}</h5>
        <div class="text-secondary fs-small">
          <i class="bi bi-eye"></i>
          <span class="ms-1">{{ modelInfo.entry }}次潜入 </span>
          <i class="bi bi-clock ms-2"></i>
          <span class="ms-1">{{ modelInfo.absoluteTime }}</span>
        </div>

        <div class="d-flex align-items-center text-secondary mt-md-3 mt-2 mb-1">
          <scene-icon
            icon="bi-hand-thumbs-up-fill"
            :quantity="modelInfo.like"
          />
          <scene-icon icon="bi-star-fill" :quantity="modelInfo.collection" />
          <scene-icon icon="bi-download" :quantity="modelInfo.download" />
          <scene-icon icon="bi-share-fill" :quantity="modelInfo.share" />
        </div>

        <hr class="m-0" />

        <scene-avatar
          v-if="uploaderInfo.avatar != ''"
          class="mt-md-4 mt-3 mb-md-3 mb-2"
          :avatar="uploaderInfo.avatar"
          :uploader="uploaderInfo.uploader"
          :description="uploaderInfo.description"
          :subscribe="uploaderInfo.subscribe"
        />

        <p class="text-dark mb-md-3 mb-2 fs-small">
          {{ modelInfo.description }}
        </p>

        <hr class="m-0" />

        <h6 class="my-4 comment">{{ modelInfo.comment }} 条评论</h6>

        <div class="mb-4">
          <div class="d-flex align-items-center">
            <img class="avatar" src="../assets/user_three.jpeg" alt="avatar" />

            <textarea
              class="form-control ms-md-3 ms-2"
              rows="2"
              placeholder="发表一条公开评论"
            ></textarea>
          </div>
          <div class="text-end mt-2">
            <button class="btn btn-sm me-2">
              <i class="bi bi-emoji-smile"></i>
              <span> 表情</span>
            </button>
            <button class="btn btn-sm btn-primary" disabled>评论</button>
          </div>
        </div>

        <scene-comment
          avatar="avatar.jpg"
          uploader="助力每一个梦想"
          comment="大佬厉害୧(๑•̀◡•́๑)૭"
          time="3天前"
          like="287"
        />
      </div>

      <div class="d-lg-block d-none rightbar">
        <scene-wrap-row
          id="0"
          name="xxx"
          cover="gun.png"
          title="[模型]枪"
          uploader="瑶瑶"
          entry="56"
          time="4天前"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import SceneTankComp from "../components/scene/SceneTankComp.vue";
import SceneWrapRow from "../components/scene/SceneWrapRow.vue";
import SceneIcon from "../components/scene/SceneIcon.vue";
import SceneAvatar from "../components/scene/SceneAvatar.vue";
import SceneComment from "../components/scene/SceneComment.vue";
import { getGame, getUser } from "../api/test";

export default {
  components: {
    Navbar,
    SceneTankComp,
    SceneWrapRow,
    SceneIcon,
    SceneAvatar,
    SceneComment,
  },
  data() {
    return {
      modelInfo: {
        // path: "models/gltf/scene.glb",
        // title:
        //   "xxxx",
        // entry: 2333,
        // relativeTime: "3天前",
        // absoluteTime: "2021-11-9 14:35:58",
        // like: 888,
        // collection: 333,
        // download: 144,
        // share: 99,
        // description:
        //   "",
        // comment: 16,
      },
      uploaderInfo: {
        uploader: "",
        avatar: "",
        description: "",
        subscribe: null,
      },
    };
  },
  mounted() {
    let qs = require("qs");
    let id = qs.stringify({
      id: this.$route.params.id,
    });
    getGame(id)
      .then((res) => {
        this.modelInfo = res.data;
        console.log("获取游戏成功");
      })
      .catch((err) => {
        console.log(err);
      });

    getUser(id)
      .then((res) => {
        this.uploaderInfo = res.data;
        console.log(this.uploaderInfo);
        console.log(res.data);
        console.log("获取用户成功");
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
.two {
  /* height: calc(100%);
  width: 100%; */
  position: relative;
  padding-bottom: 60%;
}
.test {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
textarea {
  resize: none;
  background-color: #efefef;
}
.c-row {
  max-width: 1650px;
  margin: 0 auto;
}
.leftbar {
  flex: 1;
  width: calc(100% - 430px);
}
.rightbar {
  width: 400px;
  margin-left: 30px;
}
.fs-small {
  font-size: 14px;
}
.comment {
  font-size: 18px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  h5 {
    font-size: 18px;
  }
  .leftbar {
    flex: 1;
    width: calc(100% - 320px);
  }
  .rightbar {
    width: 300px;
    margin-left: 20px;
  }
}
@media (max-width: 992px) {
  .leftbar {
    width: 100%;
  }
  .fs-small {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  h5 {
    font-size: 14px;
  }
  .avatar {
    width: 35px;
    height: 35px;
  }
}
</style>
