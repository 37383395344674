<template>
  <div class="text-center text-secondary">
    <p>&copy; 2021 域起网络</p>
    <p>
      <a
        class="text-secondary"
        href="/"
        target="_blank"
        >内部测试，严禁外泄</a
      >
    </p>
    <p>
      <a
        class="text-secondary"
        href="/"
        target="_blank"
        >关于</a
      >
      <a
        class="text-secondary ms-2"
        href="/"
        target="_blank"
        >联系</a
      >
    </p>
  </div>
</template>

<style scoped>
p {
  font-size: 12px;
}
</style>
