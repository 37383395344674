// const Mock = require('mockjs')

const game_home_list = [
    {
        id: 0,
        name: "xxx",
        cover: "gun.png",
        avatar: "user_two.jpeg",
        title: "[模型]枪",
        uploader: "瑶瑶",
        entry: 52,
        time: "4天前"
    },
    {
        id: 1,
        name: "tank",
        cover: "cover.png",
        avatar: "user_two.jpeg",
        title: "[动画]坦克",
        uploader: "瑶瑶",
        entry: 66,
        time: "3天前"
    },
    {
        id: 2,
        name: "tankpvp",
        cover: "tank.jpg",
        avatar: "user_one.jpeg",
        title: "[游戏]坦克大战",
        uploader: "青梅竹马",
        entry: 81,
        time: "2天前"
    },
    {
        id: 3,
        name: "tankpvp",
        cover: "happy_eliminating.png",
        avatar: "user_one.jpeg",
        title: "[游戏]开心消消乐",
        uploader: "青梅竹马",
        entry: 36,
        time: "1天前"
    }
]

const gameList = [
    {
        path: "../models/gltf/gun/scene.gltf",
        title:
            "[模型]枪",
        entry: 52,
        relativeTime: "4天前",
        absoluteTime: "2021-11-9 14:35:58",
        like: 34,
        collection: 46,
        download: 23,
        share: 16,
        description:
            "",
        comment: 1,
    },
    {
        path: "../models/gltf/gun/scene.gltf",
        title: "[动画]坦克",
        entry: 66,
        relativeTime: "3天前",
        absoluteTime: "2021-11-10 14:35:58",
        like: 888,
        collection: 333,
        download: 144,
        share: 99,
        description:
            "",
        comment: 1,
    },
    {
        path: "/tank_pvp/index.html",
        title: "坦克大战",
        entry: 81,
        relativeTime: "2天前",
        absoluteTime: "2021-11-15 14:35:58",
        like: 888,
        collection: 333,
        download: 144,
        share: 99,
        description:
            "",
        comment: 1,
    },
    {
        path: "/happy_eliminating/index.html",
        title: "开心消消乐",
        entry: 81,
        relativeTime: "1天前",
        absoluteTime: "2021-12-08 11:35:58",
        like: 888,
        collection: 333,
        download: 144,
        share: 99,
        description:
            "",
        comment: 1,
    }
]

const userList = [
    {
        uploader: "瑶瑶",
        avatar: "user_two.jpeg",
        description: "",
        subscribe: 15,
    },
    {
        uploader: "瑶瑶",
        avatar: "user_two.jpeg",
        description: "",
        subscribe: 15,
    },
    {
        uploader: "青梅竹马",
        avatar: "user_one.jpeg",
        description: "",
        subscribe: 13,
    }
]


// const gameOne = {
//     path: "models/gltf/scene.glb",
//     title:
//         "xxxx",
//     entry: 2333,
//     relativeTime: "3天前",
//     absoluteTime: "2021-11-9 14:35:58",
//     like: 888,
//     collection: 333,
//     download: 144,
//     share: 99,
//     description:
//         "",
//     comment: 16,
// }

// const gameTwo = {
//     path: "../models/gltf/gun/scene.gltf",
//     title:
//         "xxxx",
//     entry: 2333,
//     relativeTime: "3天前",
//     absoluteTime: "2021-11-9 14:35:58",
//     like: 888,
//     collection: 333,
//     download: 144,
//     share: 99,
//     description:
//         "",
//     comment: 16,
// }

export default {
    'get|/magic-wave': () => {
        return {
            status: 200,
            message: 'success',
            data: game_home_list
        };
    },

    'post|/add_new_game': (data) => {
        if (data.body !== null) {
            let qs = require('qs')
            let title = (qs.parse(data.body)).title
            game_home_list[game_home_list.length] = {
                id: gameList.length,
                name: 'tank',
                cover: "cover.png",
                avatar: "user_three.jpeg",
                title: title,
                uploader: "喵~",
                entry: "1",
                time: "1天前"
            }
            gameList[gameList.length] = {
                path: "../models/gltf/gun/scene.gltf",
                title: title,
                entry: 1,
                relativeTime: "1天前",
                absoluteTime: "2021-12-3 14:35:58",
                like: 1,
                collection: 1,
                download: 1,
                share: 1,
                description: "",
                comment: 1,
            }
            userList[userList.length] = {
                uploader: "喵~",
                avatar: "user_three.jpeg",
                description: "",
                subscribe: 3,
            }
            return {
                status: 200,
                message: 'success',
            }
        }
        console.log('[服务]错误')
        return {
            status: 500,
            message: '错误'
        }
    },

    'post|/get_game': (data) => {
        console.log('[服务]进入获取游戏')
        if (data.body == null) {
            return {
                status: 500,
                message: '游戏ID为空'
            }
        }
        console.log(data)
        let qs = require('qs')
        let id = (qs.parse(data.body)).id
        return {
            status: 200,
            message: 'success',
            data: gameList[id]
        }
    },
    
    'post|/get_user': (data) => {
        console.log('[服务]进入获取用户')
        if (data.body == null) {
            return {
                status: 500,
                message: '游戏用户ID为空'
            }
        }
        console.log(data)
        let qs = require('qs')
        let id = (qs.parse(data.body)).id
        return {
            status: 200,
            message: 'success',
            data: userList[id]
        }
    }
}