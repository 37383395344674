<template>
  <navbar v-if="settingShow == true" />
  <div v-if="settingShow == false" class="full_screen_title">
    <span class="full_screen_name">{{ modelInfo.title }}</span>
    <button @click="exitFullScreen" class="exit_full_screen">退出全屏</button>
  </div>

  <div class="my-4 mx-xl-4 mx-sm-3 mx-2">
    <div class="d-lg-flex c-row">
      <div class="leftbar">
        <div style="height: 100vh" v-if="settingShow == false"></div>
        <!-- <scene-tank-comp :path="modelInfo.path" /> -->
        <div class="two">
          <iframe :src="modelInfo.path" class="test"></iframe>
        </div>
                <div style="height: 100vh" v-if="settingShow == false"></div>
        <div class="tool">
          <button @click="fullScreen" class="tool_full_screen" title="全屏">
            <img src="../assets/full_screen.png" class="tool_full_screen_ico" />
          </button>
        </div>

        <h5 class="mt-md-3 mt-2">{{ modelInfo.title }}</h5>
        <div class="text-secondary fs-small">
          <i class="bi bi-eye"></i>
          <span class="ms-1">{{ modelInfo.entry }}次潜入 </span>
          <i class="bi bi-clock ms-2"></i>
          <span class="ms-1">{{ modelInfo.absoluteTime }}</span>
        </div>

        <div class="d-flex align-items-center text-secondary mt-md-3 mt-2 mb-1">
          <scene-icon
            icon="bi-hand-thumbs-up-fill"
            :quantity="modelInfo.like"
          />
          <scene-icon icon="bi-star-fill" :quantity="modelInfo.collection" />
          <scene-icon icon="bi-download" :quantity="modelInfo.download" />
          <scene-icon icon="bi-share-fill" :quantity="modelInfo.share" />
        </div>

        <hr class="m-0" />

        <scene-avatar
          class="mt-md-4 mt-3 mb-md-3 mb-2"
          :avatar="uploaderInfo.avatar"
          :uploader="uploaderInfo.uploader"
          :description="uploaderInfo.description"
          :subscribe="uploaderInfo.subscribe"
        />

        <p class="text-dark mb-md-3 mb-2 fs-small">
          {{ modelInfo.description }}
        </p>

        <hr class="m-0" />

        <h6 class="my-4 comment">{{ modelInfo.comment }} 条评论</h6>
        <div class="mb-4">
          <div class="d-flex align-items-center">
            <img class="avatar" src="../assets/user_three.jpeg" alt="avatar" />

            <textarea
              class="form-control ms-md-3 ms-2"
              rows="2"
              placeholder="发表一条公开评论"
            ></textarea>
          </div>
          <div class="text-end mt-2">
            <button class="btn btn-sm me-2">
              <i class="bi bi-emoji-smile"></i>
              <span> 表情</span>
            </button>
            <button class="btn btn-sm btn-primary" disabled>评论</button>
          </div>
        </div>

        <scene-comment
          avatar="avatar.jpg"
          uploader="追光者"
          comment="大佬666"
          time="1天前"
          like="24"
        />
      </div>

      <div class="d-lg-block d-none rightbar" v-if="settingShow == true">
        <scene-wrap-row
          id="0"
          name="xxx"
          cover="gun.png"
          title="[模型]枪"
          uploader="瑶瑶"
          entry="54"
          time="4天前"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
// import SceneTankComp from "../components/scene/SceneTankComp.vue";
import SceneWrapRow from "../components/scene/SceneWrapRow.vue";
import SceneIcon from "../components/scene/SceneIcon.vue";
import SceneAvatar from "../components/scene/SceneAvatar.vue";
import SceneComment from "../components/scene/SceneComment.vue";
import { getGame } from "../api/test";

export default {
  components: {
    Navbar,
    // SceneTankComp,
    SceneWrapRow,
    SceneIcon,
    SceneAvatar,
    SceneComment,
  },
  data() {
    return {
      modelInfo: {
        // path: "models/gltf/scene.glb",
        // title:
        //   "xxxx",
        // entry: 2333,
        // relativeTime: "3天前",
        // absoluteTime: "2021-11-9 14:35:58",
        // like: 888,
        // collection: 333,
        // download: 144,
        // share: 99,
        // description:
        //   "",
        // comment: 16,
      },
      uploaderInfo: {
        uploader: "青梅竹马",
        avatar: "user_one.jpeg",
        description: "",
        subscribe: 15,
      },
      settingShow: true,
    };
  },
  mounted() {
    let qs = require("qs");
    let id = qs.stringify({
      id: this.$route.params.id,
    });
    getGame(id)
      .then((res) => {
        this.modelInfo = res.data;
        console.log(res);
        console.log("获取游戏成功");
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    fullScreen() {
      let game = document.getElementsByClassName("two")[0];
      console.log(game);
      // var ele = document.documentElement;
      // window.open('/tank_pvp/index.html')
      // if (ele.requestFullscreen) {
      //     game.requestFullscreen();
      // } else if (ele.mozRequestFullScreen) {
      //     game.mozRequestFullScreen();
      // } else if (ele.webkitRequestFullScreen) {
      //     game.webkitRequestFullScreen();
      // }
      game.style.width = "100vw";
      game.style.height = "calc(100vh - 35px)";
      // game.style.object-fit = 'fill';
      game.style.position = "fixed";
      game.style.top = "35px";
      game.style.left = "0";
      game.style.right = '0'
      game.style.padding = 0;
      document.body.parentNode.style.overflowY = "hidden";
      this.settingShow = false;
    },
    exitFullScreen() {
      let game = document.getElementsByClassName("two")[0];
      game.style.width = "100%";
      game.style.height = "auto";
      // game.style.object-fit = 'fill';
      game.style.position = "relative";
      game.style.top = '0'
      // game.style.left = '0'
      game.style.padding = "0 0 60% 0";
      game.style.megin = '0'
      document.body.parentNode.style.overflowY = "hidden";
      this.settingShow = true;
      document.documentElement.style.overflowY = 'scroll'; 
    },
  },
};
</script>

<style scoped>
.full_screen_title {
  position: fixed;
  top: 0;
  width: 100%;
  height: 36px;
  background-color: #fff;
  overflow: hidden;
  margin: auto;
}
.full_screen_name {
  font-size: 16px;
  line-height: 36px;
  padding: 0 20px;
  float: left;
  font-weight: 600;
}
.exit_full_screen {
  float: right;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  position: relative;
  margin: 5px 5px;
  line-height: 24px;
  padding: 0 8px 0 8px;
  cursor: pointer;
  background-color: #ff7e1d;
  color: #fff;
}
/* .exit_full_screen {
  position:absolute;
  right: 0;
} */
.two {
  /* height: calc(100%);
  width: 100%; */
  position: relative;
  padding-bottom: 60%;
  /* position: relative;
  height: 100vh; */
  /* object-fit: fill; */
}
.test {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tool {
  position: relative;
  height: 40px;
  border-radius: 0 0 5px 3px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px rgb(236, 236, 236);
}
.tool_full_screen {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 3px;
  background: rgb(255, 255, 255);
  position: absolute;
  right: 0;
  padding-bottom: 0;
  margin-top: 2px;
}
.tool_full_screen_ico {
  height: 30px;
  /* width: 50px; */
}
textarea {
  resize: none;
  background-color: #efefef;
}
.c-row {
  max-width: 1650px;
  margin: 0 auto;
}
.leftbar {
  flex: 1;
  width: calc(100% - 430px);
}
.rightbar {
  width: 400px;
  margin-left: 30px;
}
.fs-small {
  font-size: 14px;
}
.comment {
  font-size: 18px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  h5 {
    font-size: 18px;
  }
  .leftbar {
    flex: 1;
    width: calc(100% - 320px);
  }
  .rightbar {
    width: 300px;
    margin-left: 20px;
  }
}
@media (max-width: 992px) {
  .leftbar {
    width: 100%;
  }
  .fs-small {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  h5 {
    font-size: 14px;
  }
  .avatar {
    width: 35px;
    height: 35px;
  }
}
</style>
