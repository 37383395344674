import request from '../utils/request'

export function getGameHomeList() {
    return request({
        url:'/magic-wave',
        method: 'get',
    })
}

export function addNewGame(data) {
    return request({
        url:'/add_new_game',
        method: 'post',
        data
    })
}

export function getGame(data) {
    return request({
        url:'/get_game',
        method: 'post',
        data
    })
}

export function getUser(data) {
    return request({
        url:'/get_user',
        method: 'post',
        data
    })
}